import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{staticClass:"px-16 d-none d-sm-flex",attrs:{"color":"transparent"}},[_c(VProgressLinear,{attrs:{"height":"2px","color":"black"}}),_c(VCol,{attrs:{"cols":"4"}},[_c(VRow,[_c('p',{staticClass:"text-h7"},[_c('b',[_vm._v("@2023")]),_vm._v(" | Todos os direitos reservados"),_c('br'),_vm._v("LIMAR CREATIVE AGENCY")])])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,[_c(VCol,{staticClass:"mt-3",attrs:{"align":"right"}},[_c(VImg,{attrs:{"contain":"","max-height":_vm.$vuetify.breakpoint.xs ? 25 : 35,"max-width":_vm.$vuetify.breakpoint.xs ? 25 : 35,"src":require("../assets/LIMAR_arrow.png")}})],1),_c(VCol,{staticClass:"mt-2"},[_c('p',{staticClass:"text-h7"},[_vm._v("A nossa história começa consigo."),_c('br'),_c('b',[_c('a',{attrs:{"href":"mailto:geral@limar.pt"}},[_vm._v("geral@limar.pt")])])])])],1)],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"2"}},_vm._l((_vm.socialmedia),function(sm){return _c(VBtn,{key:sm.icon,staticClass:"mx-1",attrs:{"icon":"","href":sm.href,"target":"_blank"}},[_c(VIcon,{attrs:{"size":"24px","color":"black"}},[_vm._v(" "+_vm._s(sm.icon)+" ")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }