<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>



<style>
@font-face {
  font-family: "Actay";
  src: local("Actay"), 
  url('./fonts/Actay-Regular.ttf') format('truetype');
} 
</style>

<style lang="scss">
@import "./scss/variables.scss";

v-app {
  font-family: $body-font-family !important;
}

</style>