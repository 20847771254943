<template>
    <section class="fullpage">
        <v-container fluid fill-height>
            <v-row>
                <v-col cols="9" offset="2" offset-md="1" md="5" align-self="center">
                    <v-scroll-x-transition>
                        <p class="text-h4 text-md-h2 text-xl-h1">Queremos o seu <span
                            style="color:#4747f0;; border: 1.5px solid black; border-radius: 50%;padding: 10px;">sucesso</span>
                        e o nosso também.</p>
                    </v-scroll-x-transition>
                    
                </v-col>
                <v-col cols="10" offset="1" offset-md="1" md="4" class="mb-10 mt-10">
                    <v-row class="py-xl-3">
                        <v-treeview
                        class="text-h7 text-md-h6 text-xl-h5"
                        :items="services"
                        dense
                        shaped
                        hoverable
                        open-on-click
                        transition
                        :open.sync="openNodes"
                        expand-icon=""
                        >
                        <template v-slot:label="{ item } ">
                                <div @mouseover="if (item.id < 9) {openNode(item)}">
                                    <span v-if="item.id < 9" style="color:black; font-size: medium; font-weight: lighter;"> 0{{item.id}}</span> 
                                    <span style="color:black;" v-bind:style=" (item.id > 10 ) ? 'font-weight: lighter; font-size:medium;' : true ">{{item.name}}</span>
                                     
                                </div>
                        </template>
                        </v-treeview>
                    </v-row>
            </v-col>
        </v-row>
    </v-container>
</section></template>

<style>
.v-treeview-node__label{
  white-space: normal !important;
}
</style>
<script>

export default {
    data() {
        return {
            services: [
                {
                    "id": 1,
                    "name": "Estratégia de Marca",
                    "children": [
                        { "id": 11, "name": 'Análise de mercado' },
                        { "id": 12, "name": 'Criação e definição da marca' },
                        { "id": 13, "name": 'Planeamento Estratégico para o posicionamento da Marca' },
                        { "id": 14, "name": 'Criação e definição dos Objetivos e Valores da marca' }
                    ]
                },
                {
                    "id": 2,
                    "name": "Branding e Design",
                    "children": [
                        { "id": 21, "name": "Consultoria e rebranding visual" },  
                        { "id": 22, "name": "Definição da identidade visual da marca" },
                        { "id": 23, "name": "Desenvolvimento do Naming" },
                        { "id": 24, "name": "Definição de Slogan e Tagline" },
                        { "id": 25, "name": "Peças gráficas de pequenos e grandes formatos" },
                        { "id": 26, "name": "Estacionário" },
                        { "id": 27, "name": "Sinalética" },
                        { "id": 28, "name": "Cartazes" },
                        { "id": 29, "name": "Design editorial" },
                        { "id": 210, "name": "Embalagem" }
                    ]
                },
                {
                    "id": 3,
                    "name": "Design Digital",
                    "children": [
                        { "id": 31, "name": "Design de Redes Sociais" },
                        { "id": 32, "name": "Copywriting" },
                        { "id": 33, "name": "Execução de um Plano Personalizado de Comunicação"},
                        { "id": 34, "name": "Social Ads" },
                        { "id": 35, "name": "Newsletters" },
                        { "id": 36, "name": "Fotografia e Vídeo" }
                    ]
                },
                {
                    "id": 4,
                    "name": "Web Design",
                    "children": [
                        { "id": 41, "name": "Design de interfaces" },
                        { "id": 42, "name": "Landing pages" },
                        { "id": 43, "name": "Mobile design" },
                        { "id": 44, "name": "Websites" },
                        { "id": 45, "name": "E-commerce" }
                    ]
                },
                {
                    "id": 5,
                    "name": "Marketing",
                    "children": [
                        { "id": 51, "name": "Estratégia de Comunicação e Vendas" },
                        { "id": 52, "name": "SEO e SEM" },
                        { "id": 53, "name": "Email Marketing" },
                        { "id": 54, "name": "Geração de Leads" },
                        { "id": 55, "name": "Divulgação em canais sociais" }
                    ]
                },
                {
                    "id": 6,
                    "name": "Contactos Sociais",
                    "children": [
                        { "id": 61, "name": "Ativações de marca" },
                        { "id": 62, "name": "Contacto com influencers" },
                        { "id": 63, "name": "Clipping" },
                        { "id": 64, "name": "Comunicados de imprensa" },
                        { "id": 65, "name": "Lançamento de marcas e produtos" }
                    ]
                }


            ],
            openNodes: [],
        }
        
    },
    methods: {
        updateOpenNode() {
            // // Update the activeNode data property when a node is activated
            // this.items.forEach(item => {
            //     if (item.id !== node.id) {
            //         item.oepn = false;
            //     }
            // });

            
        },
        openNode(item) {
            if (this.openNodes.length >= 1) {
                this.openNodes.shift()
            }
            this.openNodes.push(item.id)
            
        },
        closeNode() {
            this.openNodes.shift()
        }
    }
}
</script>
