import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'


// Configuration VueAnalytics
Vue.use(VueGtag, {
  config: {
      id: 'G-J452XGG1Q5'
  }
}, router)
Vue.config.productionTip = false
Vue.prototype.$http = Axios;
new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
