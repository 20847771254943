<template>
  <v-footer color="transparent" class="px-16 d-none d-sm-flex">
    <v-progress-linear height="2px" color="black"></v-progress-linear>
    <v-col cols="4">
      <v-row>
        <p class="text-h7 "><b>@2023</b> | Todos os direitos reservados<br>LIMAR CREATIVE AGENCY</p>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col class="mt-3" align="right">
          <v-img contain class="" :max-height="$vuetify.breakpoint.xs ? 25 : 35"
            :max-width="$vuetify.breakpoint.xs ? 25 : 35" src="../assets/LIMAR_arrow.png">
          </v-img>
        </v-col>
        <v-col class="mt-2">
          <p class=" text-h7">A nossa história começa consigo.<br><b><a href="mailto:geral@limar.pt">geral@limar.pt</a></b></p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="text-right">
      <v-btn v-for="sm in socialmedia" :key="sm.icon" class="mx-1" icon :href="sm.href" target="_blank">
        <v-icon size="24px" color="black">
          {{ sm.icon }}
        </v-icon>
      </v-btn>
    </v-col>
  </v-footer>
</template>

<script>

export default {
    data() {
        return {
            socialmedia: [
                {
                icon: "mdi-facebook",
                href: "https://fb.com/limarcreativeagency",
                },
                {
                icon: "mdi-linkedin",
                href: "https://linkedin.com/company/limar-creative-agency"
                },
                {
                icon: "mdi-instagram",
                href: "https://instagram.com/limar.pt"
                }
            ]
        }
    }
}

</script>
