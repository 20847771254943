import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"fullpage limar"},[_c(VToolbar,{staticClass:"ma-10",attrs:{"elevation":"0","color":"transparent"}},[_c(VToolbarTitle,[_c(VImg,{attrs:{"max-width":_vm.$vuetify.breakpoint.xs ? 100 : 200,"src":require("../assets/LIMAR_logo.png")}})],1),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","href":"https://cloud.limar.pt/","target":"_blank"}},[_c(VIcon,{attrs:{"color":"black"}},[_vm._v("mdi-account-lock-outline")])],1)],1)],1),_c(VContainer,{attrs:{"fluid":"","fill-height":"","transition":"slide-x-transition"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"9","offset":"2"}},[_c('p',{staticClass:"text-h4 text-md-h2 text-lg-h2 text-xl-h1"},[_vm._v("Somos a "),_c('span',{staticStyle:{"border":"1.5px solid black","border-radius":"50%","padding":"10px"}},[_vm._v("agência")]),_vm._v(" de marketing e comunicação que "),_c('span',{staticStyle:{"color":"#4747f0"}},[_vm._v("entende")]),_vm._v(" o seu negócio. ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }