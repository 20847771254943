import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"fullpage"},[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"9","offset":"2","offset-md":"1","md":"5","align-self":"center"}},[_c(VScrollXTransition,[_c('p',{staticClass:"text-h4 text-md-h2 text-xl-h1"},[_vm._v("Queremos o seu "),_c('span',{staticStyle:{"color":"#4747f0","border":"1.5px solid black","border-radius":"50%","padding":"10px"}},[_vm._v("sucesso")]),_vm._v(" e o nosso também.")])])],1),_c(VCol,{staticClass:"mb-10 mt-10",attrs:{"cols":"10","offset":"1","offset-md":"1","md":"4"}},[_c(VRow,{staticClass:"py-xl-3"},[_c(VTreeview,{staticClass:"text-h7 text-md-h6 text-xl-h5",attrs:{"items":_vm.services,"dense":"","shaped":"","hoverable":"","open-on-click":"","transition":"","open":_vm.openNodes,"expand-icon":""},on:{"update:open":function($event){_vm.openNodes=$event}},scopedSlots:_vm._u([{key:"label",fn:function({ item } ){return [_c('div',{on:{"mouseover":function($event){if (item.id < 9) {_vm.openNode(item)}}}},[(item.id < 9)?_c('span',{staticStyle:{"color":"black","font-size":"medium","font-weight":"lighter"}},[_vm._v(" 0"+_vm._s(item.id))]):_vm._e(),_c('span',{staticStyle:{"color":"black"},style:((item.id > 10 ) ? 'font-weight: lighter; font-size:medium;' : true)},[_vm._v(_vm._s(item.name))])])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }