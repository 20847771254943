<template>
    <section class="fullpage">
        
        <v-container fluid fill-height>
            <v-row>
                <v-col cols="8" offset="2" offset-md="1" md="5" align-self="center">
                    <p class="text-h5 text-md-h2 text-xl-h1">É das<span
                            style="color: #dec1ff; border: 1.5px solid black; border-radius: 50%; padding: 10px;">conversas</span><br>que
                        nascem<span style="color:#4747f0;"> grandes ideias.</span></p>
                    <!-- <p class="text-h5 text-md-h1"><span style="color:#4747f0;">Grandes ideias</span> surgem a partir de <span style="color: #dec1ff; border: 1.5px solid black; border-radius: 50%;">conversas.</span></p> -->
                </v-col>
                <v-col cols="8" offset="2" offset-md="0" md="5">
                    <v-row no-gutters class="my-md-5">
                        <v-col cols="12" md="6" lg="6" xl="6" class="pr-md-5">
                            <v-text-field dense label="Nome" color="lime darken-2" v-model="message.nome"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="Empresa" color="lime darken-2"
                                v-model="message.empresa"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-md-5">
                        <v-col cols="12" md="6" lg="6" xl="6" class="pr-md-5">
                            <v-text-field dense label="Contacto" color="lime darken-2"
                                v-model="message.contacto"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="E-mail" color="lime darken-2" v-model="message.email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-md-5">
                        <v-col cols="11">
                            <v-textarea color="lime darken-2" v-model="message.message" auto-grow rows="10" dense>
                                <template v-slot:label>
                                    <div>
                                        Fale-nos do seu projeto.
                                    </div>
                                </template>
                            </v-textarea>
                        </v-col>
                        <v-col cols="1" align-self="end">
                            <v-dialog v-model="sent" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2" fab dark icon @click="sendMessage()" :loading="sending" v-bind="attrs"
                                    v-on="on">
                                    <v-icon color="grey">
                                        mdi-send
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-card>

                                <v-card-text class="pt-8">
                                    Agradecemos o seu contacto. Iremos analisar a sua mensagem e daremos feedback assim que
                                    possível.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="lime" text @click="sent = false">
                                        Ok
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-container>
        <desktop-footer></desktop-footer>
            <mobile-footer></mobile-footer>
    </section>
</template>


<script>
import MobileFooter from '@/components/MobileFooter.vue';
import DesktopFooter from '@/components/DesktopFooter.vue';


export default {
    components: {MobileFooter, DesktopFooter},
    data() {
        return {
            sending: false,
            sent: false,
            message: {
                nome:'',
                email:'',
                empresa:'',
                contacto:'',
                message:''
            }
        }
    },
    methods: {
        async sendMessage() {
        this.sending = true
        var config = {
            method: "post",
            url: 'https://api.telegram.org/bot6091343399:AAGal0poHByC_298Xv1VHZ29ExnWiqwISxw/sendMessage',
            headers: {
            "Content-Type": "application/json"
            },
            data: {
            "chat_id": '220156298',
            "text": 'De: ' + this.message.nome + '\n' + 'E-mail: ' + this.message.email + '\n' + 'Empresa: ' + this.message.empresa + '\n' + 'Contacto: ' + this.message.contacto + '\n' + 'Mensagem: ' + this.message.message
            },
            timeout: 5000,
        };      

        const r = await this.$http(config)

        if (r.status === 200) {
            this.sending = false
            this.sent = true
            return true
        } else {
            this.sending = false
            this.sent = true
            return false
        }
        }
    }
}
</script>
