<template>
    <v-footer color="transparent"  class="px-10 d-flex d-sm-none
                    ">
        <v-progress-linear height="2px" color="black" class="mb-1"></v-progress-linear>
        <v-col cols="7">
            <v-row>
                <p style="font-size: x-small;"><b>@2023</b> | Todos os direitos reservados LIMAR CREATIVE AGENCY
                    <b>geral@limar.pt</b></p>
            </v-row>
        </v-col>
        <v-col cols="5" class="text-right">
            <v-btn v-for="sm in socialmedia" :key="sm.icon" x-small class="mx-1" icon :href="sm.href" target="_blank">
                <v-icon size="24px" color="black">
                    {{ sm.icon }}
                </v-icon>
            </v-btn>
        </v-col>
    </v-footer>
</template>



<script>

export default {
    data() {
        return {
            socialmedia: [
                {
                icon: "mdi-facebook",
                href: "https://fb.com/limarcreativeagency",
                },
                {
                icon: "mdi-linkedin",
                href: "https://linkedin.com/company/limar-creative-agency"
                },
                {
                icon: "mdi-instagram",
                href: "https://instagram.com/limar.pt"
                }
            ]
        }
    }
}

</script>
