<template>
    <section class="fullpage limar">
        <!-- <v-row class="mt-5 mx-15" align="center" justify="center">
            <v-col cols="10">
                
            </v-col>
            <v-col cols="2">
                
            </v-col>
        </v-row> -->
        <v-toolbar
            elevation="0"
            color="transparent"
            class="ma-10"
        >
            <v-toolbar-title>
                <v-img :max-width="$vuetify.breakpoint.xs ? 100 : 200"
                    src="../assets/LIMAR_logo.png">
                </v-img>
                
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                
                <v-btn icon href="https://cloud.limar.pt/" target="_blank"><v-icon color="black">mdi-account-lock-outline</v-icon></v-btn>
            </v-toolbar-items>
            

            
        </v-toolbar>
        <v-container fluid fill-height transition="slide-x-transition">
            <v-row>
                <v-col cols="9" offset="2">
                    <p class=" text-h4 text-md-h2 text-lg-h2 text-xl-h1">Somos a
                        <span style="border: 1.5px solid black; border-radius: 50%; padding: 10px;">agência</span> de
                        marketing e comunicação que <span style="color:#4747f0;">entende</span> o seu negócio.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>


<script>
export default {
    data() {
        return {}
    }
}
</script>
