import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{staticClass:"px-10 d-flex d-sm-none",attrs:{"color":"transparent"}},[_c(VProgressLinear,{staticClass:"mb-1",attrs:{"height":"2px","color":"black"}}),_c(VCol,{attrs:{"cols":"7"}},[_c(VRow,[_c('p',{staticStyle:{"font-size":"x-small"}},[_c('b',[_vm._v("@2023")]),_vm._v(" | Todos os direitos reservados LIMAR CREATIVE AGENCY "),_c('b',[_vm._v("geral@limar.pt")])])])],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"5"}},_vm._l((_vm.socialmedia),function(sm){return _c(VBtn,{key:sm.icon,staticClass:"mx-1",attrs:{"x-small":"","icon":"","href":sm.href,"target":"_blank"}},[_c(VIcon,{attrs:{"size":"24px","color":"black"}},[_vm._v(" "+_vm._s(sm.icon)+" ")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }